<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="voice">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <h1 :class="['section-header', headerColor]">{{ sectionTitle }}</h1>
                    <div>
                        <p>The purpose of Baptist Health's copy style guidelines is to allow multiple contributors to maintain a cohesive approach writing style across all platforms (print, web, digital, presentations, etc.), and to provide style clarity on terms and phrases that are commonly used throughout the organization. This guide supplements the Associated Press Stylebook, our primary reference tool for writing and editing.</p>
                        <hr/>
                        <h1 :class="['copy-style-header', headerColor]">b</h1>
                        <h2 :class="['copy-style-title', headerColor]">Baptist Health</h2>
                        <p>Baptist Health should always be fully written out; never BH or “Baptist,” even on second reference, for external communications.</p>
                        <p>Baptist Health of Northeast Florida may be used to identify the health system's location for materials published beyond the Northeast Florida region. “Baptist Health System” is only used in legal documents not in general communications; Baptist Health system is acceptable but not preferred.</p><br/>

                        <h2 :class="['copy-style-title', headerColor]">Baptist Health hospitals</h2>
                        <p>Initials and acronyms are not acceptable <span class="u-textItalic">(i.e., BMCJ, BMCB, BCMN, BMCS, BMC-Jacksonville, WCH, etc.).</span></p>
                        <p>Identify as follows:</p>
                        <div class="Grid Grid--withGap">
                            <div class="Grid-cell u-md-size1of2">
                                <span class="u-textUnderline">Correct first mention</span>
                                <ul>
                                    <li>Baptist Medical Center Jacksonville**</li>
                                    <li>Baptist Medical Center Beaches</li>
                                    <li>Baptist Medical Center Clay</li>
                                    <li>Baptist Medical Center Nassau</li>
                                    <li>Baptist Medical Center South</li>
                                    <li>Wolfson Children's Hospital</li>
                                </ul>
                            </div>
                            <div class="Grid-cell u-md-size1of2">
                                <span class="u-textUnderline">Correct subsequent mentions</span>*
                                <ul>
                                    <li>Baptist Jacksonville**</li>
                                    <li>Baptist Beaches</li>
                                    <li>Baptist Clay</li>
                                    <li>Baptist Nassau</li>
                                    <li>Baptist South</li>
                                    <li>Wolfson Children's</li>
                                </ul>
                            </div>
                        </div>
                        <p class="u-textSize02 u-padTop2 ">*In internal communications, using the shortened name is acceptable at first mention.<br/>
                        **Do not use Downtown in place of Jacksonville, formally or informally.</p>


                        <h1 :class="['copy-style-header', headerColor]">c</h1>
                        <h2 :class="['copy-style-title', headerColor]">Credentials</h2>
                        <p>When listing medical degrees or other advanced degrees, list the MD or the highest degree obtained first. Certifications should be listed after the highest degree obtained. In general, a maximum of three credentials is preferred but exceptions may be made based on audience and application. For example, all degrees and credentials would be listed in a bio, but for Juice articles, email marketing and short-format external communications, a maximum of two credentials is preferred.</p>


                        <h1 :class="['copy-style-header', headerColor]">d</h1>
                        <h2 :class="['copy-style-title', headerColor]">Departments</h2>
                        <p>Use exact names. Eliminate the word department when it is implied. Capitalize the names of specific departments, but not job titles.</p>
                        <p class="u-padLeft5">Adrian Brown is a senior project manager in Human Resources.</p>


                        <h1 :class="['copy-style-header', headerColor]">e</h1>
                        <h2 :class="['copy-style-title', headerColor]">email</h2>
                        <p>Do not use a hyphen in the word email. For email addresses, follow this naming convention: First.Last@bmcjax.com. Typing words with the appropriate capitalizations helps readers with visual and/or cognitive impairments, as well as screen-reading devices.</p><br/>

                        <h2 :class="['copy-style-title', headerColor]">emergency center</h2>
                        <p>For external communications, emergency center is preferred over emergency department or emergency room. However, because of its familiarity to consumers, ER is an acceptable abbreviation when necessary (short ads, billboards, etc.) or as a second reference. ER is used on Baptist Health website(s) for search engine optimization purposes..</p>
                        <p>Satellite emergency center is preferred over freestanding emergency center when referring to an emergency center not on a hospital campus. Do not use the acronym FSED in external communications.</p>
                        <p>The names of Baptist Health's satellite emergency centers are written in copy in two styles depending on the type of location:<br/>
                            <span class="u-padLeft5">Baptist Emergency and Imaging Center at Nassau Crossing</span><br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency Center North</span><br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency Center Oakleaf</span><br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency Center St. Augustine</span><br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency Town Center</span></p>

                        <p>If referring to more than one satellite emergency center in copy, it may be written as follows:<br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency Centers in the St. Johns Town Center and Town Center areas</span></p>

                        <p>If context calls for reference to the outpatient Imaging Center co-located within the Emergency Center, use as follows:<br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency & Imaging Center Oakleaf</span><br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency & Imaging Center North <span class="u-textItalic">(etc.)</span></span></p>

                        <p>In a list, the names may be consolidated as follows <span class="u-textItalic">(advertisement format):</span><br/>
                            <span class="u-padLeft5">Baptist/Wolfson Children's Emergency & Imaging Centers</span><br/>
                                <span class="u-padLeft10">Oakleaf</span><br/>
                                <span class="u-padLeft10">9868 Family Place</span><br/>
                                <span class="u-padLeft10">Jacksonville, FL 32222</span><br/><br/>

                                <span class="u-padLeft10">North Jacksonville</span><br/>
                                <span class="u-padLeft10">11250 Baptist Health Drive</span><br/>
                                <span class="u-padLeft10">Jacksonville, FL 32218</span><br/><br/>

                                <span class="u-padLeft10">Etc.</span><br/>
                                <span class="u-padLeft10"></span></p>


                        <h2 :class="['copy-style-title', headerColor]">Epic</h2>
                        <p>Epic is Baptist Health's integrated patient care platform, providing one medical record for all patients. It should never be referred to in all caps <span class="u-textItalic">(i.e., EPIC is incorrect).</span></p>


                        <h1 :class="['copy-style-header', headerColor]">h</h1>
                        <h2 :class="['copy-style-title', headerColor]">headlines</h2>
                        <p>Use sentence case for headlines, meaning that only the first word and proper nouns are capitalized. Do not use a period at the end of a title of headline. Do use a period at the end of a subtitle.</p>
                        <p><span class="u-padLeft5">Headline: Robotic surgery makes for faster recovery</span><br/>
                        <span class="u-padLeft5">Subhead: Learn how Baptist Health surgeons are on the leading edge.</span></p><br/>

                        <h2 :class="['copy-style-title', headerColor]">health care</h2>
                        <p>Health care is always two words instead of one, even if it comes before a noun. Use as one word only when it is the name of a specific agency, company or award <span class="u-textItalic">(i.e., Florida Healthcare Group)</span> or when referring to benefits as governed by the IRS <span class="u-textItalic">(i.e., Flexible Spending Account for Healthcare).</span></p>


                        <h1 :class="['copy-style-header', headerColor]">j</h1>
                        <h2 :class="['copy-style-title', headerColor]">Job titles</h2>
                        <p>Job titles are not capitalized unless they precede a person's name. Lowercase job titles following a person's name are preferred.</p>
                        <span class="u-padLeft5">Michael A. Mayo, DHA, FACHE, president and chief executive officer of Baptist Health, has more than 30 years of health care administration experience.</span><br/>
                        <span class="u-padLeft5">President and Chief Executive Officer Michael Mayo has more than 30 years of health care administration experience.</span><br/><br/>
                        <p>Job title abbreviations <span class="u-textItalic">(i.e., CEO, COO)</span> are acceptable on second reference.</p>
                        <p>Some titles are not formal titles and thus are not capitalized before or after a person's name:</p> <span class="u-padLeft5">“We are pleased to donate this piece to advance art and healing in our community,” said philanthropist Mary Walker.</span><br/><br/>
                        <p>There may be exceptions to capitalizations in letter or email signatures, pull quotes and graphics.</p>


                        <h1 :class="['copy-style-header', headerColor]">o</h1>
                        <h2 :class="['copy-style-title', headerColor]">OB/GYN</h2>
                        <p>An abbreviation for obstetrician-gynecologist. The acronym is well-known and acceptable upon first reference.</p>


                        <h1 :class="['copy-style-header', headerColor]">p</h1>
                        <h2 :class="['copy-style-title', headerColor]">Phone numbers</h2>
                        <p>Use periods rather than dashes.</p>
                        <span class="u-padLeft5">904.202.2000</span><br/>
                        <span class="u-padLeft5">904.202.4YOU (4986) is the correct way to write a phone number with specific spelling.</span><br/>
                        <span class="u-padLeft5">904.202.4000, ext. 4906</span><br/><br/>
                        <p>Include area code, even for 904, as 324 is an area code also for the Northeast Florida region as the population grows. Do not add parenthesis around the area code.</p>


                        <h1 :class="['copy-style-header', headerColor]">t</h1>
                        <h2 :class="['copy-style-title', headerColor]">telemedicine, telestroke, telehealth</h2>
                        <p>Not tele-medicine, tele-stroke, tele-health.</p><br/>

                        <h2 :class="['copy-style-title', headerColor]">time</h2>
                        <p>Use figures except for noon and midnight. A colon separates hours from minutes.</p>
                            <span class="u-padLeft5">11 am, 1 pm, 4:30 pm, midnight.</span><br/><br/>
                        <p>Use am and pm in lowercase and do not use periods. Leave one space between the numerical time and the am or pm.</p>
                        <span class="u-padLeft5">The train leaves at 9 pm, one Tuesday a week, and DeDe missed it.</span><br/>
                        <span class="u-padLeft5">The event will be held from noon - 2 pm. Capitalizing “noon” on an invitation where it is the first word of a line is an exception.</span><br/><br/>
                        <p>Do not use :00 <span class="u-textItalic">(i.e., 4:00 pm should be 4 pm).</span><br/>Do not use 12 noon or 12 midnight.</p>


                        <h1 :class="['copy-style-header', headerColor]">u</h1>
                        <h2 :class="['copy-style-title', headerColor]">URLs</h2>
                        <p>Include capitalization for the first letter of each word in a URL. Don't include https://www unless it is essential for calling up the website.</p>
                        <p>Typing words with the appropriate capitalizations helps readers with visual and/or cognitive impairments, as well as screen-reading devices.</p>
                        <p><span class="u-padLeft5">BaptistJax.com</span><br/>
                        <span class="u-padLeft5">BaptistJax.com/Heart</span><br/>
                        <span class="u-padLeft5">WolfsonChildrens.com</span><br/></p>


                        <h1 :class="['copy-style-header', headerColor]">w</h1>
                        <h2 :class="['copy-style-title', headerColor]">Well-being</h2>
                        <p>Hyphenate in all cases rather than one word.</p><br/>

                        <h2 :class="['copy-style-title', headerColor]">Wolfson Children's Hospital</h2>
                        <p>Use Wolfson Children's Hospital in first reference. Wolfson Children's is acceptable on second reference or on first reference in internal publications. Never use initials WCH or just Wolfson. Never Wolfson's Children's Hospital.</p>




                    </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'StyleSection',
    props: {
        headerColor: String,
        sectionTitle: String,
    }
};
</script>

