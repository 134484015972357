<template>
    <div class="menu-trigger" @click="toggleMenu" :style="triggerStyle">
        <svg v-if="isMenuVisible" width="24" height="24" class="x" viewBox="0 0 24 24">
            <path d="M18.01,20.132,3.868,5.99A1.5,1.5,0,0,1,5.99,3.868L20.132,18.01a1.5,1.5,0,1,1-2.121,2.121Z"></path>
            <path
                d="M3.868,20.132a1.5,1.5,0,0,1,0-2.121L18.01,3.868A1.5,1.5,0,1,1,20.132,5.99L5.99,20.132A1.5,1.5,0,0,1,3.868,20.132Z">
            </path>
        </svg>
        <svg v-else width="24" height="24" class="hamburger" viewBox="0 0 24 24">
            <g stroke="currentColor" stroke-linecap="round" stroke-width="3" fill="none">
                <polyline class="Icon-scaleOut" points="4,6 20,6" />
                <polyline class="Icon-rotate1of8" points="4,12 20,12" />
                <polyline class="Icon-show Icon-rotateCounter1of8" points="4,12 20,12" />
                <polyline class="Icon-scaleOut" points="4,18 20,18" />
            </g>
        </svg>
    </div>

    <transition name="slide">
        <nav v-if="isMenuVisible" id="slide-menu" :style="menuStyle">
            <ul>
                <li><span class="nav-item--no-link nav-section-header">Core Elements</span></li>
                <li id="menu-voice" class="voice" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/voice`">Voice</router-link>
                </li>
                <li id="menu-style" class="style" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/style`">Style</router-link>
                </li>
                <li id="menu-structure" class="structure" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/structure`">Brand Structure</router-link>
                </li>
                <li id="menu-logos" class="logos" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/logos`">Logos</router-link>
                </li>
                <li id="menu-color" class="color" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/color`">Color</router-link>
                </li>
                <li id="menu-typography" class="typography" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/typography`">Typography</router-link>
                </li>
                <li id="menu-icons" class="icons" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/icons`">Icons</router-link>
                </li>
                <!-- <li><span class="nav-item--no-link nav-section-header sep">Internal</span></li> -->
                <!-- <li id="menu-signature" class="signature" @click="scrollToTop">
                    <router-link class="nav-item" :to="`${basePath}/emailsignature`">Email Signature</router-link>
                </li> -->
                <!-- <li @click="resetHeaderVisibility">
                    <router-link class="nav-item backBtn" to="/">Back to Home</router-link>
                </li> -->
            </ul>
        </nav>
    </transition>

</template>

<script>
export default {
    props: {
        basePath: {
            type: String,
            required: true,
            default: '/baptist'
        }
    },
    data() {
        return {
            isMenuVisible: false,
        };
    },
    computed: {
        currentBasePath() {
            return '/' + this.$route.path.split('/')[1];
        },
        menuStyle() {
            return {
                right: this.isMenuVisible ? '0' : '-250px',
            };
        },
        triggerStyle() {
            const visibleRightPosition = '258px';
            const hiddenRightPosition = '8px';

            return {
                right: this.isMenuVisible ? visibleRightPosition : hiddenRightPosition,
                transition: 'right 0.3s ease'
            };
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuVisible = !this.isMenuVisible;
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
};
</script>
