<template>
    <StyleSection
        :sectionTitle="sectionTitle"
        :headerColor="headerColor" />
</template>

<script>
import StyleSection from '@/components/StyleSection.vue';

export default {
    name: 'BMDA',
    components: {
        StyleSection
    },
    data() {
        return {
            sectionTitle: "Copy Style",
            headerColor:"u-textBMDA"
        };
    }
};
</script>