<template>
    <section class="Band-content u-bgWhite u-padTop8" id="logos">
        <div class="u-padSides8">
            <div class="u-containSpread">

                <HeaderSection :sectionTitle="sectionTitle" :headerColor="headerColor" />
                <p>
                    Maintain the original proportions of the Baptist Health and Wolfson Children's Hospital logos to
                    prevent them from appearing stretched or compressed. Do not modify or recreate any Baptist Health /
                    Wolfson Children's Hospital logos. They should not be joined or placed with any other logo or
                    brandmark unless Creative Services has approved it.
                </p>
                <h2 class="u-padTop8">Primary Logo</h2>
                <div class="Grid Grid--withGap u-padBottom10">
                    <div class="Grid-cell u-sm-sizefull u-padTop10 u-lg-padTop4">
                        <div class="u-textCenter">
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-sizeFull">
                                    <svg class="logo_sq" viewBox="0 0 329.86 466.03">
                                        <path
                                            d="M107.64,148.82c4.25,0,7.73-3.47,7.73-7.72v-61.73c0-4.25-3.48-7.72-7.73-7.72H36.73c-4.25,0-7.73,3.47-7.73,7.72v61.73c0,4.25,3.48,7.72,7.73,7.72h70.91Z"
                                            fill="#e21d39" stroke-width="0" />
                                        <path
                                            d="M34.04,64.77c-4.56,0-6.18-3.08-3.6-6.85L68.26,2.8c2.58-3.76,6.76-3.73,9.27.07l36.45,54.98c2.52,3.8.85,6.91-3.71,6.91H34.04Z"
                                            fill="#2bace2" stroke-width="0" />
                                        <path
                                            d="M294.92,148.82c4.25,0,7.72-3.47,7.72-7.72v-61.73c0-4.25-3.47-7.72-7.72-7.72h-70.91c-4.26,0-7.73,3.47-7.73,7.72v61.73c0,4.25,3.47,7.72,7.73,7.72h70.91Z"
                                            fill="#03917f" stroke-width="0" />
                                        <path
                                            d="M221.21,64.77c-4.57,0-6.2-3.08-3.61-6.85L255.4,2.8c2.6-3.76,6.77-3.73,9.29.07l36.44,54.98c2.53,3.8.86,6.91-3.71,6.91h-76.21Z"
                                            fill="#2bace2" stroke-width="0" />
                                        <path
                                            d="M128.13,64.77c-4.56,0-6.18-3.08-3.61-6.85L162.34,2.8c2.59-3.76,6.76-3.73,9.28.07l36.45,54.98c2.52,3.8.86,6.91-3.72,6.91h-76.22Z"
                                            fill="#2bace2" stroke-width="0" />
                                        <path
                                            d="M130.44,71.65h70.91c4.26,0,7.72,3.46,7.72,7.72v61.73c0,4.26-3.46,7.72-7.72,7.72h-70.92c-4.26,0-7.72-3.46-7.72-7.72v-61.72c0-4.26,3.46-7.73,7.73-7.73Z"
                                            fill="#ffd65b" stroke-width="0" />
                                        <path
                                            d="M45.83,239.8h.17l12.19-42.59h7.07l12.68,42.59h.16l12.02-41.78h12.52l-20.73,62.48h-8.77l-12.19-40.06h-.16l-11.54,40.06h-8.78l-19.5-62.48h12.52l12.35,41.78Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M139.2,239.38c0,12.36-8.46,21.22-20.96,21.22s-20.97-8.86-20.97-21.22,8.53-21.29,20.97-21.29,20.96,8.86,20.96,21.29ZM108.81,239.38c0,5.37,3.57,11.14,9.43,11.14s9.42-5.77,9.42-11.14-3.57-11.22-9.42-11.22-9.43,5.7-9.43,11.22Z"
                                            fill="#000" stroke-width="0" />
                                        <path d="M156.77,259.3h-11.38v-68.92h11.38v68.92Z" fill="#000"
                                            stroke-width="0" />
                                        <path
                                            d="M178.59,259.3h-11.37v-30.32h-3.82v-9.59h3.82v-11.7c0-9.75,4.06-18.29,15.03-18.29,1.87,0,3.74.41,5.44,1.3v10.16c-1.46-.73-2.93-1.38-4.63-1.38-5.04,0-4.47,6.58-4.47,9.84v10.08h9.1v9.59h-9.1v30.32Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M212.73,228.82c-1.62-1.54-3.74-2.76-6.02-2.76-1.87,0-3.73,1.46-3.73,3.41,0,2.93,4.14,3.74,8.21,5.52,4.14,1.8,8.21,4.56,8.21,11.45,0,8.87-7.16,14.15-15.53,14.15-5.35,0-10.56-2.44-14.05-6.67l5.2-6.74c2.2,2.43,5.11,4.63,8.45,4.63,2.52,0,4.54-1.71,4.54-4.3,0-3.5-3.98-4.31-7.96-6.02-3.99-1.7-7.97-4.06-7.97-10.48,0-7.48,5.21-12.92,12.68-12.92,4.22,0,9.59,1.3,12.83,4.14l-4.87,6.58Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M265.44,239.38c0,12.36-8.45,21.22-20.96,21.22s-20.97-8.86-20.97-21.22,8.53-21.29,20.97-21.29,20.96,8.86,20.96,21.29ZM235.05,239.38c0,5.37,3.58,11.14,9.43,11.14s9.42-5.77,9.42-11.14-3.57-11.22-9.42-11.22-9.43,5.7-9.43,11.22Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M282.87,223.05h.17c2.35-3.57,6.34-4.96,10.4-4.96,10.73,0,13.81,7.56,13.81,16.83v24.38h-11.37v-21.13c0-4.8.24-10.97-6.17-10.97-6.75,0-6.83,7.56-6.83,12.35v19.75h-11.38v-39.91h11.38v3.66Z"
                                            fill="#000" stroke-width="0" />
                                        <path d="M308.65,291.02l-11.69,24.39-7.56-.9,8.85-24.7,10.4,1.22Z" fill="#000"
                                            stroke-width="0" />
                                        <path
                                            d="M43.88,307.69c-3.33-4.07-8.37-6.5-13.49-6.5-11.46,0-18.04,10.56-18.04,21.12s6.83,20.65,17.96,20.65c5.12,0,10.24-2.68,13.57-6.5v14.22c-4.46,2.11-8.94,3.67-13.9,3.67-17.22,0-29.98-14.88-29.98-31.79s12.27-32.74,30.23-32.74c4.79,0,9.51,1.3,13.65,3.57v14.31Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M62.83,283.79v32.34h.16c2.36-3.24,6.34-4.63,10.4-4.63,10.73,0,13.81,7.55,13.81,16.82v24.38h-11.37v-21.13c0-4.8.24-10.97-6.18-10.97-6.75,0-6.83,7.56-6.83,12.34v19.75h-11.38v-68.91h11.38Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M108.35,299.5c0,3.75-3.01,6.76-6.74,6.76s-6.75-3.01-6.75-6.76,3.01-6.74,6.75-6.74,6.74,3.09,6.74,6.74ZM107.29,352.7h-11.37v-39.9h11.37v39.9Z"
                                            fill="#000" stroke-width="0" />
                                        <path d="M127.41,352.7h-11.38v-68.91h11.38v68.91Z" fill="#000"
                                            stroke-width="0" />
                                        <path
                                            d="M163.29,348.97h-.16c-2.36,3.24-6.59,5.04-10.73,5.04-11.7,0-18.37-10.65-18.37-21.46s6.74-21.05,18.21-21.05c4.14,0,8.37,1.78,10.89,4.96h.16v-32.67h11.37v68.91h-11.37v-3.73ZM164.42,332.72c0-5.37-3.57-11.14-9.42-11.14s-9.43,5.77-9.43,11.14,3.57,11.22,9.43,11.22,9.42-5.86,9.42-11.22Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M194.99,316.95h.16c2.28-3.5,5.69-5.45,9.91-5.45,1.63,0,3.25.41,4.71.97l-1.38,10.57c-1.38-.98-3.01-1.47-4.71-1.47-7.88,0-8.7,6.99-8.7,13.17v17.96h-11.38v-39.9h11.38v4.15Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M221.6,334.59c0,5.04,1.95,10.72,7.88,10.72,3.73,0,6.09-2.28,7.63-5.44l10.49,1.71c-2.77,7.96-9.75,12.43-18.12,12.43-12.35,0-19.91-8.78-19.91-20.89s6.42-21.61,19.34-21.61,18.93,9.67,18.93,21.7v1.38h-26.24ZM237.11,327.52c-.57-4.06-3.16-8.05-7.56-8.05s-7.07,3.9-7.64,8.05h15.2Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M265.2,316.46h.16c2.36-3.57,6.34-4.96,10.41-4.96,10.73,0,13.81,7.55,13.81,16.82v24.38h-11.38v-21.13c0-4.8.24-10.97-6.17-10.97-6.74,0-6.83,7.56-6.83,12.34v19.75h-11.38v-39.9h11.38v3.66Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M323.2,322.23c-1.63-1.54-3.74-2.76-6.02-2.76-1.87,0-3.74,1.46-3.74,3.41,0,2.93,4.15,3.74,8.22,5.52,4.14,1.79,8.2,4.55,8.2,11.46,0,8.85-7.16,14.14-15.52,14.14-5.36,0-10.56-2.44-14.06-6.66l5.2-6.75c2.19,2.43,5.11,4.63,8.45,4.63,2.51,0,4.54-1.7,4.54-4.3,0-3.49-3.98-4.31-7.96-6.01-3.99-1.71-7.97-4.07-7.97-10.49,0-7.47,5.2-12.92,12.68-12.92,4.23,0,9.59,1.3,12.84,4.14l-4.87,6.58Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M58.28,407.6v-24.13h11.95v61.27h-11.95v-27.39h-22.1v27.39h-11.95v-61.27h11.95v24.13h22.1Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M118.55,424.82c0,12.36-8.46,21.21-20.97,21.21s-20.97-8.85-20.97-21.21,8.54-21.3,20.97-21.3,20.97,8.87,20.97,21.3ZM88.16,424.82c0,5.37,3.57,11.14,9.43,11.14s9.43-5.77,9.43-11.14-3.57-11.21-9.43-11.21-9.43,5.69-9.43,11.21Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M144.4,414.27c-1.62-1.54-3.74-2.76-6.02-2.76-1.87,0-3.74,1.46-3.74,3.42,0,2.92,4.14,3.74,8.21,5.52,4.15,1.79,8.21,4.55,8.21,11.46,0,8.86-7.16,14.14-15.52,14.14-5.36,0-10.56-2.43-14.06-6.66l5.2-6.74c2.19,2.44,5.12,4.63,8.45,4.63,2.52,0,4.56-1.71,4.56-4.3,0-3.5-3.99-4.3-7.96-6.02-3.98-1.7-7.97-4.06-7.97-10.48,0-7.48,5.2-12.93,12.68-12.93,4.22,0,9.59,1.3,12.83,4.15l-4.87,6.59Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M168.62,408.49c2.61-3.09,6.83-4.97,11.06-4.97,11.29,0,18.2,10.57,18.2,21.06s-6.65,21.45-18.45,21.45c-4.06,0-8.36-1.79-10.64-5.04h-.17v25.03h-11.37v-61.2h11.37v3.66ZM186.34,424.75c0-5.37-3.58-11.13-9.43-11.13s-9.43,5.77-9.43,11.13,3.58,11.22,9.43,11.22,9.43-5.85,9.43-11.22Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M216.79,391.54c0,3.74-3.02,6.75-6.75,6.75s-6.76-3.01-6.76-6.75,3.01-6.74,6.76-6.74,6.75,3.09,6.75,6.74ZM215.72,444.74h-11.37v-39.91h11.37v39.91Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M237.1,444.74h-11.38v-30.32h-3.9v-9.59h3.9v-13.48h11.38v13.48h6.99v9.59h-6.99v30.32Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M285.02,444.74h-11.22v-3.66h-.16c-2.44,3.17-6.67,4.95-10.8,4.95-11.7,0-18.38-10.64-18.38-21.45s6.75-21.06,18.21-21.06c4.14,0,8.37,1.79,11.13,5.05v-3.74h11.22v39.91ZM274.93,424.75c0-5.37-3.66-11.13-9.51-11.13s-9.43,5.77-9.43,11.13,3.58,11.22,9.43,11.22,9.51-5.85,9.51-11.22Z"
                                            fill="#000" stroke-width="0" />
                                        <path d="M305.07,444.74h-11.38v-68.92h11.38v68.92Z" fill="#000"
                                            stroke-width="0" />
                                    </svg>
                                </div>
                                <div class="Grid-cell u-containProse">
                                    <a
                                        href="https://cdn.baptistjax.com/raw/upload/v1722870939/BrandGuidelines/Logos/Wolfson_logos.zip"><button
                                            type="button"
                                            class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull u-borderColorBlackAlt u-textBlackAlt u-textUpper"
                                            value="Download Icons">Download Logo Files</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 class="u-padTop9">Baptist/Wolfson Lockup</h2>
                <div class="Grid Grid--withGap u-padTop3 u-lg-padTop2 u-padBottom10">

                    <div class="Grid-cell u-sm-sizefull">
                        <div class="u-textCenter">
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-sizeFull">
                                    <svg class="logo_rect" viewBox="0 0 619.94 335.78">
                                        <defs>
                                            <linearGradient id="b" x1="406.68" y1="76.53" x2="468.81" y2="76.53"
                                                gradientUnits="userSpaceOnUse">
                                                <stop offset="0" stop-color="#dc2629" />
                                                <stop offset=".58" stop-color="#dd5759" />
                                                <stop offset="1" stop-color="#df7e80" />
                                            </linearGradient>
                                        </defs>
                                        <path
                                            d="M410.3,46.6c-3.28,0-4.45-2.21-2.59-4.93l27.2-39.66c1.86-2.71,4.86-2.68,6.68.05l26.22,39.55c1.81,2.73.61,4.98-2.67,4.98h-54.84Z"
                                            fill="#00aeef" stroke-width="0" />
                                        <path
                                            d="M412.23,51.55h51.02c3.07,0,5.56,2.49,5.56,5.56v44.41c0,3.07-2.49,5.56-5.56,5.56h-51.03c-3.07,0-5.56-2.49-5.56-5.56v-44.41c0-3.07,2.49-5.56,5.56-5.56Z"
                                            fill="#cb2128" stroke-width="0" />
                                        <path
                                            d="M468.81,57.11c0-3.06-2.5-5.56-5.56-5.56h-51.02c-3.06,0-5.56,2.49-5.56,5.56v44.41c50.3-9.22,62.14-44.41,62.14-44.41Z"
                                            fill="url(#b)" stroke-width="0" />
                                        <path
                                            d="M412.23,51.55h51.02c3.07,0,5.56,2.49,5.56,5.56v44.41c0,3.07-2.49,5.56-5.56,5.56h-51.03c-3.07,0-5.56-2.49-5.56-5.56v-44.41c0-3.07,2.49-5.56,5.56-5.56Z"
                                            fill="#e31937" stroke-width="0" />
                                        <path
                                            d="M544.88,46.6c-3.28,0-4.45-2.21-2.59-4.93l27.19-39.66c1.87-2.71,4.87-2.68,6.69.05l26.22,39.55c1.81,2.73.62,4.98-2.67,4.98h-54.84Z"
                                            fill="#00aeef" stroke-width="0" />
                                        <path
                                            d="M546.81,51.55h51.02c3.07,0,5.56,2.49,5.56,5.56v44.41c0,3.07-2.49,5.55-5.55,5.55h-51.02c-3.07,0-5.56-2.49-5.56-5.56v-44.41c0-3.06,2.49-5.55,5.55-5.55Z"
                                            fill="#00907e" stroke-width="0" />
                                        <path
                                            d="M477.59,46.6c-3.29,0-4.44-2.21-2.59-4.93l27.2-39.66c1.86-2.71,4.86-2.68,6.68.05l26.23,39.55c1.81,2.73.62,4.98-2.68,4.98h-54.83Z"
                                            fill="#00aeef" stroke-width="0" />
                                        <path
                                            d="M530.54,107.07c3.07,0,5.56-2.5,5.56-5.56v-44.41c0-3.06-2.5-5.56-5.56-5.56h-51.01c-3.06,0-5.56,2.49-5.56,5.56v44.41c0,3.06,2.49,5.56,5.56,5.56h51.01Z"
                                            fill="#ffd65a" stroke-width="0" />
                                        <path
                                            d="M422.35,178.39h.12l8.48-29.63h4.92l8.82,29.63h.11l8.36-29.06h8.71l-14.42,43.47h-6.1l-8.48-27.87h-.11l-8.03,27.87h-6.11l-13.57-43.47h8.71l8.59,29.06Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M487.3,178.1c0,8.6-5.88,14.76-14.58,14.76s-14.59-6.16-14.59-14.76,5.94-14.81,14.59-14.81,14.58,6.16,14.58,14.81ZM466.16,178.1c0,3.73,2.49,7.75,6.56,7.75s6.55-4.02,6.55-7.75-2.49-7.8-6.55-7.8-6.56,3.96-6.56,7.8Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path d="M499.52,191.96h-7.92v-47.94h7.92v47.94Z" fill="#231f20"
                                            stroke-width="0" />
                                        <path
                                            d="M514.71,191.96h-7.91v-21.09h-2.66v-6.67h2.66v-8.14c0-6.78,2.83-12.72,10.46-12.72,1.3,0,2.61.29,3.79.91v7.07c-1.01-.51-2.04-.96-3.22-.96-3.51,0-3.11,4.58-3.11,6.84v7.01h6.33v6.67h-6.33v21.09Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M538.46,170.75c-1.13-1.07-2.6-1.92-4.19-1.92-1.3,0-2.6,1.02-2.6,2.37,0,2.04,2.88,2.61,5.71,3.85,2.88,1.25,5.71,3.17,5.71,7.97,0,6.17-4.98,9.85-10.8,9.85-3.73,0-7.35-1.7-9.78-4.64l3.62-4.69c1.52,1.69,3.56,3.22,5.88,3.22,1.75,0,3.16-1.19,3.16-3,0-2.43-2.77-2.99-5.54-4.18-2.77-1.18-5.55-2.83-5.55-7.29,0-5.2,3.62-8.99,8.83-8.99,2.94,0,6.67.91,8.93,2.88l-3.39,4.58Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M575.13,178.1c0,8.6-5.88,14.76-14.59,14.76s-14.59-6.16-14.59-14.76,5.94-14.81,14.59-14.81,14.59,6.16,14.59,14.81ZM553.98,178.1c0,3.73,2.48,7.75,6.56,7.75s6.56-4.02,6.56-7.75-2.49-7.8-6.56-7.8-6.56,3.96-6.56,7.8Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M587.25,166.74h.12c1.64-2.49,4.41-3.45,7.24-3.45,7.46,0,9.61,5.26,9.61,11.71v16.96h-7.91v-14.7c0-3.34.17-7.63-4.3-7.63-4.7,0-4.75,5.26-4.75,8.59v13.74h-7.91v-27.76h7.91v2.55Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path d="M605.19,214.03l-8.14,16.97-5.26-.63,6.16-17.18,7.23.84Z" fill="#231f20"
                                            stroke-width="0" />
                                        <path
                                            d="M420.99,225.62c-2.32-2.83-5.82-4.53-9.38-4.53-7.97,0-12.55,7.35-12.55,14.7s4.75,14.36,12.49,14.36c3.57,0,7.12-1.87,9.44-4.52v9.89c-3.11,1.47-6.22,2.55-9.67,2.55-11.98,0-20.86-10.35-20.86-22.11s8.54-22.78,21.03-22.78c3.33,0,6.62.91,9.5,2.48v9.95Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M434.17,209v22.5h.11c1.64-2.26,4.41-3.22,7.24-3.22,7.46,0,9.61,5.26,9.61,11.7v16.96h-7.91v-14.7c0-3.33.17-7.63-4.3-7.63-4.69,0-4.75,5.26-4.75,8.59v13.74h-7.92v-47.94h7.92Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M465.84,219.93c0,2.6-2.09,4.7-4.69,4.7s-4.7-2.09-4.7-4.7,2.09-4.69,4.7-4.69,4.69,2.15,4.69,4.69ZM465.1,256.94h-7.91v-27.76h7.91v27.76Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path d="M479.1,256.94h-7.91v-47.94h7.91v47.94Z" fill="#231f20"
                                            stroke-width="0" />
                                        <path
                                            d="M504.06,254.34h-.11c-1.64,2.25-4.58,3.5-7.47,3.5-8.14,0-12.78-7.41-12.78-14.93s4.69-14.64,12.67-14.64c2.88,0,5.83,1.24,7.58,3.45h.11v-22.73h7.91v47.94h-7.91v-2.6ZM504.85,243.03c0-3.73-2.48-7.75-6.56-7.75s-6.56,4.02-6.56,7.75,2.49,7.81,6.56,7.81,6.56-4.07,6.56-7.81Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M526.12,232.07h.11c1.58-2.43,3.96-3.79,6.9-3.79,1.13,0,2.26.28,3.28.68l-.96,7.35c-.96-.68-2.09-1.02-3.28-1.02-5.49,0-6.05,4.86-6.05,9.16v12.5h-7.91v-27.76h7.91v2.88Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M544.62,244.34c0,3.5,1.36,7.46,5.48,7.46,2.6,0,4.24-1.59,5.31-3.79l7.3,1.19c-1.93,5.54-6.79,8.65-12.61,8.65-8.59,0-13.85-6.11-13.85-14.53s4.47-15.03,13.46-15.03,13.17,6.73,13.17,15.09v.96h-18.26ZM555.42,239.42c-.39-2.82-2.2-5.59-5.25-5.59s-4.92,2.71-5.32,5.59h10.57Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M574.96,231.73h.11c1.65-2.49,4.41-3.45,7.24-3.45,7.47,0,9.61,5.26,9.61,11.7v16.96h-7.91v-14.7c0-3.33.17-7.63-4.3-7.63-4.69,0-4.75,5.26-4.75,8.59v13.74h-7.92v-27.76h7.92v2.54Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M615.31,235.74c-1.13-1.07-2.6-1.92-4.19-1.92-1.3,0-2.6,1.02-2.6,2.37,0,2.04,2.89,2.6,5.72,3.84,2.88,1.24,5.71,3.17,5.71,7.97,0,6.16-4.98,9.84-10.8,9.84-3.73,0-7.35-1.7-9.78-4.63l3.62-4.7c1.53,1.69,3.56,3.22,5.88,3.22,1.75,0,3.16-1.19,3.16-3,0-2.43-2.77-3-5.54-4.18-2.77-1.19-5.55-2.83-5.55-7.3,0-5.2,3.62-8.98,8.82-8.98,2.94,0,6.67.91,8.93,2.88l-3.39,4.58Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M431.01,295.13v-16.79h8.31v42.62h-8.31v-19.05h-15.38v19.05h-8.31v-42.62h8.31v16.79h15.38Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M472.94,307.11c0,8.6-5.88,14.76-14.59,14.76s-14.59-6.17-14.59-14.76,5.94-14.82,14.59-14.82,14.59,6.17,14.59,14.82ZM451.79,307.11c0,3.73,2.49,7.75,6.56,7.75s6.56-4.01,6.56-7.75-2.49-7.8-6.56-7.8-6.56,3.96-6.56,7.8Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M490.92,299.77c-1.13-1.07-2.6-1.92-4.19-1.92-1.3,0-2.6,1.01-2.6,2.37,0,2.04,2.88,2.6,5.71,3.84,2.88,1.24,5.71,3.17,5.71,7.97,0,6.17-4.98,9.84-10.8,9.84-3.73,0-7.35-1.7-9.78-4.64l3.62-4.69c1.52,1.69,3.56,3.22,5.88,3.22,1.75,0,3.17-1.19,3.17-3,0-2.43-2.77-2.99-5.54-4.18-2.77-1.18-5.55-2.82-5.55-7.29,0-5.2,3.62-9,8.83-9,2.94,0,6.67.91,8.93,2.89l-3.39,4.58Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M507.77,295.76c1.81-2.15,4.75-3.46,7.69-3.46,7.85,0,12.66,7.36,12.66,14.65s-4.63,14.93-12.83,14.93c-2.83,0-5.82-1.25-7.41-3.51h-.12v17.42h-7.91v-42.58h7.91v2.55ZM520.1,307.06c0-3.73-2.49-7.75-6.56-7.75s-6.56,4.02-6.56,7.75,2.49,7.8,6.56,7.8,6.56-4.07,6.56-7.8Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M541.28,283.95c0,2.6-2.1,4.7-4.7,4.7s-4.7-2.09-4.7-4.7,2.09-4.69,4.7-4.69,4.7,2.15,4.7,4.69ZM540.54,320.96h-7.91v-27.76h7.91v27.76Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M555.41,320.96h-7.92v-21.09h-2.71v-6.67h2.71v-9.38h7.92v9.38h4.86v6.67h-4.86v21.09Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M588.75,320.96h-7.81v-2.54h-.11c-1.7,2.2-4.64,3.45-7.52,3.45-8.14,0-12.78-7.41-12.78-14.93s4.7-14.65,12.67-14.65c2.88,0,5.83,1.25,7.74,3.52v-2.61h7.81v27.76ZM581.73,307.06c0-3.73-2.55-7.75-6.62-7.75s-6.56,4.02-6.56,7.75,2.49,7.8,6.56,7.8,6.62-4.07,6.62-7.8Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path d="M602.7,320.96h-7.92v-47.94h7.92v47.94Z" fill="#231f20"
                                            stroke-width="0" />
                                        <line x1="343.36" y1=".34" x2="343.36" y2="321.04" fill="none" stroke="#231f20"
                                            stroke-miterlimit="10" stroke-width="3.23" />
                                        <polygon
                                            points="245.99 77.62 194.7 77.59 194.7 63.31 238.94 63.31 250.31 75.69 253.2 78.65 253.13 128.16 248.12 134.22 248.12 77.71 245.99 77.62"
                                            fill="#0056a2" stroke-width="0" />
                                        <path
                                            d="M212.09,153.72l-13.24-32.17s-14.93-47.92-15.23-49.63h-2.35v7s-6.55-.03-6.55-.03v-4.53h-40.39V18.24h60.36L177.03.52h-70v61.89h-58.17v53.3l22.87,22.87v-60.84s8.35,0,8.35,0l3.8,5.56h30.16l8.84,7.05,23.81,2.19,9.91,23.98-2.94,8.2,6.93-6.65,3.51,3.48-7.5,10.9,13.73,35.39,23.42,17.44,6.31,11.34,6.13-13.05,7.12-9.39-1.24-20.47Z"
                                            fill="#0056a2" stroke-width="0" />
                                        <polygon
                                            points="132.9 139.75 104.72 139.72 104.71 167.46 133.85 196.58 189.44 196.51 183.36 190.45 132.91 190.45 132.9 139.75"
                                            fill="#0056a2" stroke-width="0" />
                                        <path
                                            d="M16.82,223.37c0-4.38.15-7.3,5.14-7.3,6.54,0,11.97,4.92,11.97,11.99,0,5.69-2.13,8.61-7.71,8.61h-4.41c-4.26-.31-4.99-.61-4.99-4.38v-8.92ZM7.79,258.04c0,7.46-6.32,5.38-6.32,7.92,0,1.08.73,1.23,2.42,1.23,1.54,0,3.45-.31,5.22-.31,3.67,0,7.35.31,18.29.31,11.97,0,18.73-6.61,18.73-16.76,0-6.92-2.79-11.07-11.75-13.45v-.15c5.22-1.77,8.01-6,8.01-10.84,0-1.84-.07-13.22-18.07-13.22-6.17,0-12.42.31-18.66.31-3.38,0-3.89-.31-4.41-.31-.37,0-1.25-.08-1.25,1.15,0,3.77,7.79-.46,7.79,10.15v33.98ZM16.82,246.05c0-4,.15-5.92,4.48-5.92,13.37,0,15.5,6.92,15.5,11.53,0,7.46-3.75,12.45-10.95,12.45s-9.04-2.15-9.04-9.45v-8.61Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M115.57,212.77c-5.22.08-10.43.31-11.46.31-5.58,0-9.62-.31-10.14-.31-1.76,0-2.06,1-2.06,1.46,0,2.84,7.64-.31,7.64,7.84v33.82c0,6.77-2.92,7.93-4.64,8.81-1.28-.25-2.85-.88-4.02-3.59l-19.84-47.04c-.96-2.31-1.1-2.46-1.76-2.46-.96,0-1.32,1.46-1.84,2.69l-19.91,46.51c-1.84,4.3-5.58,3.84-5.58,5.61,0,1,1.18.85,2.2.85,2.13,0,4.26-.31,6.47-.31,2.35,0,4.55.31,6.91.31,1.54,0,3.82.54,3.82-1.08,0-2.08-7.93-.85-7.93-6.38,0-1.85,1.03-4.54,1.54-6.07,1.69-5.07,3.23-5.38,4.48-5.38h14.47c1.91,0,2.28.77,4.7,6.76.81,2.08,1.32,3.46,1.32,5.15,0,4.69-5.22,3.84-5.22,6.07,0,1.08.95.92,2.28.92,2.13,0,4.85-.31,8.08-.31,4.85,0,6.83.31,9.48.31,1.08,0,1.75.04,2.16-.13,1.99-.09,5.44-.25,7.53-.25,1.62,0,4.85.31,9.99.31,1.03,0,1.76.15,1.76-1.15,0-2.69-7.42.23-7.42-9.38v-35.67c0-3.46,1.18-5,4.48-5,11.39,0,12.05,11.22,11.98,13.22-.07,2-.74,10.68-9.62,10.68-1.62,0-2.86-.46-3.45-.46-.88,0-1.03.92-1.03,1.46,0,.31.37,2.23,7.57,2.23,8.74,0,15.57-5.84,15.57-15.45,0-11.22-9.11-15.07-18.51-14.91ZM71.65,244.2h-9.99c-1.32,0-2.35.08-2.35-.69,0-1.08.44-1.92.81-2.85l5.66-13.61c.37-.85.66-.92.81-.92.37,0,.15,0,.59.61l6.02,13.91c.37.92.81,1.77.81,2.85,0,.77-1.03.69-2.35.69Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M157.81,257.11c0,9.15-8.3,5.46-8.3,8.45,0,1.69,1.25,1.62,2.57,1.62,2.94,0,5.36-.31,10.29-.31s7.35.31,10.29.31c2.13,0,3.6.23,3.6-1.62,0-2.15-9.4.08-9.4-9.38v-34.75c0-3.69.74-4.07,3.75-4.07h6.24c8.45-.31,6.1,8.22,9.04,8.22,1.91,0,1.1-3,.96-3.92l-.81-7.07c-.07-.77-.07-1.84-1.18-1.84-6.83,0-11.76.31-22.48.31s-15.65-.31-22.48-.31c-1.1,0-1.1,1.08-1.18,1.84l-1.18,10.15c-.15,1-.59,2.69.95,2.69,3.31,0,1.1-10.07,9.4-10.07h6.17c3.01,0,3.75.38,3.75,4.07v35.67Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M206.73,220.99c0-7.3,5.51-4.53,5.51-7.15,0-.92-.37-1.08-2.57-1.08-4.19,0-6.83.31-9.48.31s-5.44-.31-8.3-.31c-.66,0-2.5-.23-2.5,1.08,0,2.69,8.3.54,8.3,7.53v36.9c0,7.3-5.29,5.3-5.29,7.99,0,.38.15.92.81.92,1.91,0,5.07-.31,8.23-.31,5.73,0,8.45.31,11.17.31,1.69,0,2.35-.31,2.35-1.15,0-2.46-8.23,1.08-8.23-8.38v-36.67Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M218.55,262.05c.37,1.84.29,2.46,2.13,2.92,4.26,1,8.01,2.84,12.49,2.84,9.62,0,16.38-6.84,16.38-16.91s-4.85-13.07-12.71-16.68c-9.84-4.53-13.89-5.54-13.89-10.68s3.31-8.07,8.16-8.07c12.63,0,13.74,14.3,15.43,14.3s1.84-.92,1.69-2.61l-.81-10.3c-.15-1.77.08-2.92-1.03-2.92-.95,0-2.79,1.23-3.75,1.23-2.21,0-5.29-3-11.31-3-8.6,0-14.47,6.46-14.47,15.3,0,8.07,4.33,11.07,10.65,13.99,11.83,5.46,16.46,6.46,16.46,12.99,0,6.15-4.41,9.84-10.14,9.84-8.16,0-12.71-6.99-15.06-14.37-.3-1-.44-1.69-1.62-1.69-1.84,0-1.1,2.23-.88,3.3l2.28,10.53Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M272.58,257.12c0,9.15-8.3,5.46-8.3,8.46,0,1.69,1.25,1.61,2.57,1.61,2.94,0,5.36-.31,10.29-.31s7.35.31,10.29.31c2.13,0,3.6.23,3.6-1.61,0-2.15-9.4.08-9.4-9.38v-34.75c0-3.69.73-4.07,3.75-4.07h6.25c8.45-.31,6.1,8.23,9.04,8.23,1.91,0,1.1-3,.95-3.92l-.81-7.07c-.07-.77-.07-1.84-1.18-1.84-6.83,0-11.75.31-22.48.31s-15.65-.31-22.48-.31c-1.1,0-1.1,1.08-1.17,1.84l-1.18,10.15c-.15,1-.59,2.69.96,2.69,3.31,0,1.1-10.07,9.4-10.07h6.17c3.01,0,3.75.38,3.75,4.07v35.67Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M73.28,284.22c0-6.09,4.28-3.42,4.28-5.78,0-.56-.24-.74-1.25-.74-2.56,0-4.76.25-7.85.25-3.69,0-5.23-.25-7.67-.25-.83,0-1.37-.12-1.37.99,0,1.8,6.54-.43,6.54,6.41v9.23c0,1.37-.36,2.8-2.08,2.8h-17.65c-1.25,0-1.49-.81-1.49-2.49v-10.35c0-5.54,4.58-3.73,4.58-5.66,0-.62-.54-.93-1.37-.93-1.72,0-4.1.25-7.67.25-3.09,0-4.87-.25-6.66-.25-1.13,0-2.91.06-2.91.99,0,2.36,6.72-.37,6.72,6.41v28.2c0,7.71-5.05,5.47-5.05,7.96,0,.81.59.93,1.31.93,2.97,0,5.47-.25,7.96-.25,4.28,0,6.24.25,8.68.25,1.9,0,1.78-.5,1.78-.87,0-2.24-7.37,0-7.37-7.71v-9.76c0-2.74.18-3.36,2.91-3.36h15.87c2.62,0,2.44.69,2.44,3.3v11.2c0,6.03-4.34,4.35-4.34,6.53,0,.44.36.68,1.19.68,3.03,0,5.35-.25,7.49-.25,2.44,0,5.94.25,7.91.25.77,0,1.25.06,1.25-.75,0-2.43-6.18-.12-6.18-8.02v-29.2Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M93.75,284.81c0-4.29.24-4.35,7.43-4.35,11.47,0,6.83,7.28,9.63,7.28,1.37,0,1.07-2.05,1.01-2.86l-.24-6.16c0-.56-.42-1.18-1.19-1.18-5.23,0-9.33.25-13.55.25-9.1,0-13.37-.25-15.81-.25-.83,0-1.31.5-1.31,1.06,0,2.49,6.72.5,6.72,6.09v29.81c0,7.59-4.52,4.23-4.52,7.09,0,.37.36.68,1.13.68,3.27,0,5.76-.25,7.61-.25,2.97,0,6.54.25,15.57.25,4.87,0,8.38-5.1,8.38-9.95,0-1.18-.42-1.37-.65-1.37-2.26,0-1.43,8.15-16.17,8.15-4.28,0-4.04-2.24-4.04-6.1v-7.96c0-3.61-.24-4.35,2.5-4.35h4.4c6.18,0,4.52,6.59,6.42,6.59,1.43,0,1.07-2.24,1.01-3.05l-.42-11.07c-.06-1.68-.71-1.56-1.13-1.56-1.19,0-1.37,5.72-6.48,5.72h-3.74c-2.56,0-2.56-.43-2.56-3.11v-9.41Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M189.32,310.68c-2.26,0-1.43,8.15-16.17,8.15-4.28,0-4.04-2.24-4.04-6.09v-29.53c0-4.73,4.22-2.43,4.22-4.79,0-.75-.3-.81-1.55-.81-.95,0-1.67.25-6.72.25-2.26,0-5.71-.25-8.08-.25-1.25,0-1.9.5-1.9,1.06,0,2.18,6.72.86,6.72,6.14v29.42c0,5.84-2.68,5.2-3.91,5.9-1.11-.17-2.76-.32-3.92-3.01l-16.05-38.06c-.77-1.87-.89-1.99-1.43-1.99-.77,0-1.07,1.18-1.49,2.18l-16.11,37.63c-1.49,3.48-4.52,3.11-4.52,4.54,0,.81.95.68,1.78.68,1.72,0,3.45-.25,5.23-.25,1.9,0,3.69.25,5.59.25,1.25,0,3.09.43,3.09-.87,0-1.68-6.42-.68-6.42-5.16,0-1.49.83-3.67,1.25-4.91,1.37-4.11,2.62-4.35,3.63-4.35h11.71c1.55,0,1.84.62,3.8,5.47.65,1.68,1.07,2.8,1.07,4.17,0,3.79-4.22,3.11-4.22,4.91,0,.87.77.75,1.84.75,1.72,0,3.92-.25,6.54-.25,3.92,0,5.53.25,7.67.25.83,0,1.37.03,1.7-.08,3.15-.01,5.57-.25,7.37-.25,2.97,0,6.54.25,15.57.25,4.88,0,8.38-5.1,8.38-9.95,0-1.18-.42-1.37-.65-1.37ZM138.4,303.43h-8.08c-1.07,0-1.9.06-1.9-.56,0-.87.36-1.55.65-2.3l4.58-11.01c.3-.68.54-.75.65-.75.3,0,.12,0,.48.5l4.87,11.26c.3.75.65,1.43.65,2.3,0,.62-.83.56-1.9.56Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M198.35,313.46c0,7.4-6.72,4.42-6.72,6.84,0,1.37,1.01,1.31,2.08,1.31,2.38,0,4.34-.25,8.32-.25s5.94.25,8.32.25c1.72,0,2.91.19,2.91-1.31,0-1.74-7.61.06-7.61-7.59v-28.11c0-2.98.6-3.3,3.03-3.3h5.05c6.84-.25,4.93,6.65,7.31,6.65,1.55,0,.89-2.43.77-3.17l-.65-5.72c-.06-.62-.06-1.49-.95-1.49-5.53,0-9.51.25-18.19.25s-12.66-.25-18.19-.25c-.89,0-.89.87-.95,1.49l-.95,8.21c-.12.81-.48,2.18.77,2.18,2.68,0,.89-8.15,7.61-8.15h4.99c2.44,0,3.03.31,3.03,3.3v28.86Z"
                                            fill="#231f20" stroke-width="0" />
                                        <path
                                            d="M265.36,284.11c0-6.09,4.28-3.42,4.28-5.78,0-.56-.24-.75-1.25-.75-2.56,0-4.76.25-7.84.25-3.69,0-5.23-.25-7.67-.25-.83,0-1.37-.12-1.37,1,0,1.8,6.54-.44,6.54,6.41v8.77c0,1.37-.36,2.8-2.08,2.8h-17.65c-1.25,0-1.49-.81-1.49-2.49v-9.89c0-5.54,4.58-3.73,4.58-5.66,0-.62-.53-.93-1.37-.93-1.72,0-4.1.25-7.67.25-3.09,0-4.87-.25-6.66-.25-1.13,0-2.91.06-2.91,1,0,2.36,6.72-.37,6.72,6.41v27.74c0,7.71-5.05,5.47-5.05,7.96,0,.81.6.93,1.31.93,2.97,0,5.47-.25,7.97-.25,4.28,0,6.24.25,8.68.25,1.9,0,1.79-.5,1.79-.87,0-2.24-7.37,0-7.37-7.71v-9.76c0-2.74.18-3.36,2.91-3.36h15.87c2.62,0,2.44.69,2.44,3.3v11.19c0,6.03-4.34,4.35-4.34,6.53,0,.43.36.68,1.19.68,3.03,0,5.35-.25,7.49-.25,2.44,0,5.94.25,7.91.25.77,0,1.25.06,1.25-.75,0-2.43-6.18-.12-6.18-8.02v-28.73Z"
                                            fill="#231f20" stroke-width="0" />
                                    </svg>
                                </div>
                                <div class="Grid-cell u-containProse u-padTop5">
                                    <a
                                        href="https://cdn.baptistjax.com/raw/upload/v1713804964/BrandGuidelines/Logos/BaptistWolfson_logos.zip"><button
                                            type="button"
                                            class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull u-borderColorBlackAlt u-textBlackAlt u-textUpper"
                                            value="Download Icons">Download Baptist/Wolfson Lockup Files</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Grid Grid--withGap u-padTop4">
                    <p class="u-padTop10">
                        The logo can be enlarged or reduced as long as its original proportions are maintained. It
                        should never be distorted in any way.
                    </p>
                    <p>
                        <strong>Logo versions available.</strong> Master files of all approved Baptist Health and Wolfson Children's Hospital logos are available in a variety of formats, each download will contain all color
                        profiles and file formats, including:
                    </p><br />
                    <p>
                        <strong>Logo versions include:</strong>
                    <ul class="u-padLeft8">
                        <li>Color</li>
                        <li>Black & white (grayscale)</li>
                        <li>Reverse (white)</li>
                    </ul>
                    </p><br />
                    <p>
                        <strong>File formats</strong>
                    <ul class="u-padLeft8">
                        <li>.JPG (for general use)</li>
                        <li>.PNG (for digital use)</li>
                        <li>.EPS (for professional/vendor use)</li>
                    </ul>
                    </p>

                    <section>
                        <AccordionSection title="How to place a logo">
                            <p>
                                Start by opening a document in your preferred software, such as Microsoft Word or
                                PowerPoint. It's a straightforward process. Just follow the chart above to select and
                                insert the correct logo file, which should open immediately.
                            </p>
                            <p>
                                To resize a logo while maintaining its proportions:
                            </p>
                            <ul class="u-padLeft8">
                                <li>Select the logo.</li>
                                <li>Hold down the "SHIFT" key and grab a corner of the logo.</li>
                                <li>Drag the corner towards the center of the logo. Remember, never alter the logo's
                                    proportions, which could make it appear compressed or extended.</li>
                            </ul>
                            <p>
                                Always use .jpg logos at their original size or smaller to ensure the logo retains its
                                quality. Always scale down from a larger format instead of enlarging a smaller logo.
                            </p>
                        </AccordionSection>
                        <AccordionSection title="Logo size and clear space">
                            <div>
                                <p>
                                    Ensure the logo is always at least 5/8" wide to maintain legibility.
                                </p>
                                <p>
                                    Clear space, or "breathing room," is crucial around our logo. It enhances the logo's
                                    visibility and impact by freeing it from surrounding text or images. This space also
                                    sets the minimum distance between the logo and the edge of any printed material. For
                                    Baptist Health logos, the minimum clear space required is equal to half the width of
                                    the cross. More space around the logo is always preferable for optimal clarity and
                                    effectiveness.
                                </p>
                                <div class="Grid Grid--withGap u-textCenter u-padTop8">
                                    <div class="Grid-cell  u-sizeFull u-lg-size1of2">
                                        <svg class="logo_sq_clear" viewBox="0 0 596.32 734.5">
                                            <g id="b">
                                                <g id="c">
                                                    <rect width="596.32" height="734.5" fill="#efefef"
                                                        stroke-width="0" />
                                                    <rect x="75.64" y="80.36" width="442.04" height="569.42" fill="#fff"
                                                        stroke-width="0" />
                                                </g>
                                            </g>
                                            <path
                                                d="M237.37,280.22c4.25,0,7.73-3.47,7.73-7.72v-61.73c0-4.25-3.48-7.72-7.73-7.72h-70.91c-4.25,0-7.73,3.47-7.73,7.72v61.73c0,4.25,3.48,7.72,7.73,7.72h70.91Z"
                                                fill="#e21d39" stroke-width="0" />
                                            <path
                                                d="M163.77,196.17c-4.56,0-6.18-3.08-3.6-6.85l37.82-55.12c2.58-3.76,6.76-3.73,9.27.07l36.45,54.98c2.52,3.8.85,6.91-3.71,6.91h-76.23Z"
                                                fill="#2bace2" stroke-width="0" />
                                            <path
                                                d="M424.65,280.22c4.25,0,7.72-3.47,7.72-7.72v-61.73c0-4.25-3.47-7.72-7.72-7.72h-70.91c-4.26,0-7.73,3.47-7.73,7.72v61.73c0,4.25,3.47,7.72,7.73,7.72h70.91Z"
                                                fill="#03917f" stroke-width="0" />
                                            <path
                                                d="M350.94,196.17c-4.57,0-6.2-3.08-3.61-6.85l37.8-55.12c2.6-3.76,6.77-3.73,9.29.07l36.44,54.98c2.53,3.8.86,6.91-3.71,6.91h-76.21Z"
                                                fill="#2bace2" stroke-width="0" />
                                            <path
                                                d="M257.86,196.17c-4.56,0-6.18-3.08-3.61-6.85l37.82-55.12c2.59-3.76,6.76-3.73,9.28.07l36.45,54.98c2.52,3.8.86,6.91-3.72,6.91h-76.22,0Z"
                                                fill="#2bace2" stroke-width="0" />
                                            <path
                                                d="M260.17,203.05h70.91c4.26,0,7.72,3.46,7.72,7.72v61.73c0,4.26-3.46,7.72-7.72,7.72h-70.92c-4.26,0-7.72-3.46-7.72-7.72v-61.72c0-4.26,3.46-7.73,7.73-7.73Z"
                                                fill="#ffd65b" stroke-width="0" />
                                            <path
                                                d="M175.56,371.2h.17l12.19-42.59h7.07l12.68,42.59h.16l12.02-41.78h12.52l-20.73,62.48h-8.77l-12.19-40.06h-.16l-11.54,40.06h-8.78l-19.5-62.48h12.52l12.35,41.78h-.01Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M268.93,370.78c0,12.36-8.46,21.22-20.96,21.22s-20.97-8.86-20.97-21.22,8.53-21.29,20.97-21.29,20.96,8.86,20.96,21.29ZM238.54,370.78c0,5.37,3.57,11.14,9.43,11.14s9.42-5.77,9.42-11.14-3.57-11.22-9.42-11.22-9.43,5.7-9.43,11.22Z"
                                                fill="#000" stroke-width="0" />
                                            <path d="M286.5,390.7h-11.38v-68.92h11.38v68.92Z" fill="#000"
                                                stroke-width="0" />
                                            <path
                                                d="M308.32,390.7h-11.37v-30.32h-3.82v-9.59h3.82v-11.7c0-9.75,4.06-18.29,15.03-18.29,1.87,0,3.74.41,5.44,1.3v10.16c-1.46-.73-2.93-1.38-4.63-1.38-5.04,0-4.47,6.58-4.47,9.84v10.08h9.1v9.59h-9.1v30.32h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M342.46,360.22c-1.62-1.54-3.74-2.76-6.02-2.76-1.87,0-3.73,1.46-3.73,3.41,0,2.93,4.14,3.74,8.21,5.52,4.14,1.8,8.21,4.56,8.21,11.45,0,8.87-7.16,14.15-15.53,14.15-5.35,0-10.56-2.44-14.05-6.67l5.2-6.74c2.2,2.43,5.11,4.63,8.45,4.63,2.52,0,4.54-1.71,4.54-4.3,0-3.5-3.98-4.31-7.96-6.02-3.99-1.7-7.97-4.06-7.97-10.48,0-7.48,5.21-12.92,12.68-12.92,4.22,0,9.59,1.3,12.83,4.14l-4.87,6.58h.01Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M395.17,370.78c0,12.36-8.45,21.22-20.96,21.22s-20.97-8.86-20.97-21.22,8.53-21.29,20.97-21.29,20.96,8.86,20.96,21.29ZM364.78,370.78c0,5.37,3.58,11.14,9.43,11.14s9.42-5.77,9.42-11.14-3.57-11.22-9.42-11.22-9.43,5.7-9.43,11.22h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M412.6,354.45h.17c2.35-3.57,6.34-4.96,10.4-4.96,10.73,0,13.81,7.56,13.81,16.83v24.38h-11.37v-21.13c0-4.8.24-10.97-6.17-10.97-6.75,0-6.83,7.56-6.83,12.35v19.75h-11.38v-39.91h11.38v3.66h-.01Z"
                                                fill="#000" stroke-width="0" />
                                            <path d="M438.38,422.42l-11.69,24.39-7.56-.9,8.85-24.7,10.4,1.22h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M173.61,439.09c-3.33-4.07-8.37-6.5-13.49-6.5-11.46,0-18.04,10.56-18.04,21.12s6.83,20.65,17.96,20.65c5.12,0,10.24-2.68,13.57-6.5v14.22c-4.46,2.11-8.94,3.67-13.9,3.67-17.22,0-29.98-14.88-29.98-31.79s12.27-32.74,30.23-32.74c4.79,0,9.51,1.3,13.65,3.57v14.31h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M192.56,415.19v32.34h.16c2.36-3.24,6.34-4.63,10.4-4.63,10.73,0,13.81,7.55,13.81,16.82v24.38h-11.37v-21.13c0-4.8.24-10.97-6.18-10.97-6.75,0-6.83,7.56-6.83,12.34v19.75h-11.38v-68.91h11.39Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M238.08,430.9c0,3.75-3.01,6.76-6.74,6.76s-6.75-3.01-6.75-6.76,3.01-6.74,6.75-6.74,6.74,3.09,6.74,6.74ZM237.02,484.1h-11.37v-39.9h11.37v39.9Z"
                                                fill="#000" stroke-width="0" />
                                            <path d="M257.14,484.1h-11.38v-68.91h11.38v68.91Z" fill="#000"
                                                stroke-width="0" />
                                            <path
                                                d="M293.02,480.37h-.16c-2.36,3.24-6.59,5.04-10.73,5.04-11.7,0-18.37-10.65-18.37-21.46s6.74-21.05,18.21-21.05c4.14,0,8.37,1.78,10.89,4.96h.16v-32.67h11.37v68.91h-11.37v-3.73h0ZM294.15,464.12c0-5.37-3.57-11.14-9.42-11.14s-9.43,5.77-9.43,11.14,3.57,11.22,9.43,11.22,9.42-5.86,9.42-11.22Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M324.72,448.35h.16c2.28-3.5,5.69-5.45,9.91-5.45,1.63,0,3.25.41,4.71.97l-1.38,10.57c-1.38-.98-3.01-1.47-4.71-1.47-7.88,0-8.7,6.99-8.7,13.17v17.96h-11.38v-39.9h11.38v4.15h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M351.33,465.99c0,5.04,1.95,10.72,7.88,10.72,3.73,0,6.09-2.28,7.63-5.44l10.49,1.71c-2.77,7.96-9.75,12.43-18.12,12.43-12.35,0-19.91-8.78-19.91-20.89s6.42-21.61,19.34-21.61,18.93,9.67,18.93,21.7v1.38h-26.24ZM366.84,458.92c-.57-4.06-3.16-8.05-7.56-8.05s-7.07,3.9-7.64,8.05h15.2Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M394.93,447.86h.16c2.36-3.57,6.34-4.96,10.41-4.96,10.73,0,13.81,7.55,13.81,16.82v24.38h-11.38v-21.13c0-4.8.24-10.97-6.17-10.97-6.74,0-6.83,7.56-6.83,12.34v19.75h-11.38v-39.9h11.38v3.66h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M452.93,453.63c-1.63-1.54-3.74-2.76-6.02-2.76-1.87,0-3.74,1.46-3.74,3.41,0,2.93,4.15,3.74,8.22,5.52,4.14,1.79,8.2,4.55,8.2,11.46,0,8.85-7.16,14.14-15.52,14.14-5.36,0-10.56-2.44-14.06-6.66l5.2-6.75c2.19,2.43,5.11,4.63,8.45,4.63,2.51,0,4.54-1.7,4.54-4.3,0-3.49-3.98-4.31-7.96-6.01-3.99-1.71-7.97-4.07-7.97-10.49,0-7.47,5.2-12.92,12.68-12.92,4.23,0,9.59,1.3,12.84,4.14l-4.87,6.58h0Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M188.01,539v-24.13h11.95v61.27h-11.95v-27.39h-22.1v27.39h-11.95v-61.27h11.95v24.13h22.1Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M248.28,556.22c0,12.36-8.46,21.21-20.97,21.21s-20.97-8.85-20.97-21.21,8.54-21.3,20.97-21.3,20.97,8.87,20.97,21.3ZM217.89,556.22c0,5.37,3.57,11.14,9.43,11.14s9.43-5.77,9.43-11.14-3.57-11.21-9.43-11.21-9.43,5.69-9.43,11.21Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M274.13,545.67c-1.62-1.54-3.74-2.76-6.02-2.76-1.87,0-3.74,1.46-3.74,3.42,0,2.92,4.14,3.74,8.21,5.52,4.15,1.79,8.21,4.55,8.21,11.46,0,8.86-7.16,14.14-15.52,14.14-5.36,0-10.56-2.43-14.06-6.66l5.2-6.74c2.19,2.44,5.12,4.63,8.45,4.63,2.52,0,4.56-1.71,4.56-4.3,0-3.5-3.99-4.3-7.96-6.02-3.98-1.7-7.97-4.06-7.97-10.48,0-7.48,5.2-12.93,12.68-12.93,4.22,0,9.59,1.3,12.83,4.15l-4.87,6.59v-.02Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M298.35,539.89c2.61-3.09,6.83-4.97,11.06-4.97,11.29,0,18.2,10.57,18.2,21.06s-6.65,21.45-18.45,21.45c-4.06,0-8.36-1.79-10.64-5.04h-.17v25.03h-11.37v-61.2h11.37v3.66h0ZM316.07,556.15c0-5.37-3.58-11.13-9.43-11.13s-9.43,5.77-9.43,11.13,3.58,11.22,9.43,11.22,9.43-5.85,9.43-11.22Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M346.52,522.94c0,3.74-3.02,6.75-6.75,6.75s-6.76-3.01-6.76-6.75,3.01-6.74,6.76-6.74,6.75,3.09,6.75,6.74ZM345.45,576.14h-11.37v-39.91h11.37v39.91Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M366.83,576.14h-11.38v-30.32h-3.9v-9.59h3.9v-13.48h11.38v13.48h6.99v9.59h-6.99v30.32Z"
                                                fill="#000" stroke-width="0" />
                                            <path
                                                d="M414.75,576.14h-11.22v-3.66h-.16c-2.44,3.17-6.67,4.95-10.8,4.95-11.7,0-18.38-10.64-18.38-21.45s6.75-21.06,18.21-21.06c4.14,0,8.37,1.79,11.13,5.05v-3.74h11.22v39.91ZM404.66,556.15c0-5.37-3.66-11.13-9.51-11.13s-9.43,5.77-9.43,11.13,3.58,11.22,9.43,11.22,9.51-5.85,9.51-11.22Z"
                                                fill="#000" stroke-width="0" />
                                            <path d="M434.8,576.14h-11.38v-68.92h11.38v68.92Z" fill="#000"
                                                stroke-width="0" />
                                        </svg>

                                    </div>

                                </div>
                            </div>
                        </AccordionSection>
                    </section>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HeaderSection from '@/components/HeaderSection.vue';
    import AccordionSection from '@/components/AccordionSection.vue';
export default {
    name: 'WolfsonChildrens',
    components: {
        HeaderSection,
        AccordionSection
    },
    data() {
        return {
            sectionTitle: "Logos",
            headerColor: "u-textBlue",
        };
    },
    mounted() {
        // console.log('Logos component mounted');
    },
};
</script>